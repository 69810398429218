// import { createIntl, createIntlCache } from '@formatjs/intl';

// Polyfills
// import '@formatjs/intl-getcanonicallocales/polyfill';

// import '@formatjs/intl-locale/polyfill';

// import '@formatjs/intl-numberformat/polyfill';
// import '@formatjs/intl-numberformat/locale-data/en'; // locale-data for en
// import '@formatjs/intl-numberformat/locale-data/fr'; // locale-data for fr
// import '@formatjs/intl-numberformat/locale-data/de'; // locale-data for de
// // //
// import '@formatjs/intl-datetimeformat/polyfill';
// import '@formatjs/intl-datetimeformat/locale-data/en'; // locale-data for en
// import '@formatjs/intl-datetimeformat/locale-data/fr'; // locale-data for fr
// import '@formatjs/intl-datetimeformat/locale-data/de'; // locale-data for de
// import '@formatjs/intl-datetimeformat/add-all-tz' // Add ALL tz data

// import '@formatjs/intl-pluralrules/polyfill';
// import '@formatjs/intl-pluralrules/locale-data/en'; // locale-data for en
// import '@formatjs/intl-pluralrules/locale-data/fr'; // locale-data for fr
// import '@formatjs/intl-pluralrules/locale-data/de'; // locale-data for de

// Locales data
// import enData from './localizationData/en';
// import frData from './localizationData/fr';
// import deData from './localizationData/de';

export const localeDataAssociations = {
    // 'fr': frData,
    // 'en': enData,
    // 'de': deData,
};

export const enabledLanguages = [
    'fr',
    'en',
    'de',
    // 'nl',
    // 'it',
    // 'es',
    // 'da',
    // 'at',
];

export const defaultLanguage = 'fr';

// // this object will have language-specific data added to it which will be placed in the state when that language is active
// // if localization data get to big, stop importing in all languages and switch to using API requests to load upon switching languages
export const localizationData = {};


