import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import { getLanguagePath } from './IntlActions';

import withRouter from '../../components/Router/WithRouter';

function IntlWrapper({ navigate, location, params, intl, children }) {
    // useEffect(() => {
    //     console.log('IntlWrapper', params, location);
    //     if(!params.lang) {
    //         navigate(getLanguagePath(location.pathname, intl.locale));
    //     }
    // }, [params]);
    return (
        <IntlProvider {...intl}>
            {children}
        </IntlProvider>
    );
}

IntlWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  intl: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

// Retrieve data from store as props
function mapStateToProps(store) {
  return {
    intl: store.intl,
  };
}

export default connect(mapStateToProps)(IntlWrapper);
