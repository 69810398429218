import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getTranslation } from '../../IntlActions';

class Translate extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <span>{getTranslation(this.props.model, this.props.field, this.props.locale)}</span>
    }
}

function mapStateToProps(store, props) {
    return {
        locale: store.intl.locale,
    }
}

export default connect(mapStateToProps)(Translate);
