import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink, Input } from 'reactstrap';

import { WithContext as ReactTags } from 'react-tag-input';

import { getDefaultLanguage, getEnabledLanguages } from '../../IntlActions';

import HtmlEditor from '../../../../components/Text/HtmlEditor';

class IntlTabLangForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: getDefaultLanguage(),
        };

        this.getValue = this.getValue.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.handleAddTag = this.handleAddTag.bind(this);
        this.handleRemoveTag = this.handleRemoveTag.bind(this);
    }

    getValue(lang) {
        if(this.props.translations) {
            const translation = this.props.translations.find(translation => translation.lang === lang);
            if(translation) {
                return translation[this.props.name] || '';
            }
        }
        return '';
    }

    getTags(lang) {
        const tags = this.getValue(lang);
        if(tags) {
            return tags.map((tag, index) => {
                return {
                    value: `${index}`,
                    label: tag,
                };
            });
        }
        return [];
    }

    getTagsSuggestions(lang) {
        const suggestions = this.props.suggestions.find(suggestion => suggestion.lang === lang);
        if(suggestions) {
            return suggestions.list;
        }
        return [];
    }

    toggle(tab) {
        if(this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    handleChange(event, lang) {
        this.props.onChange && this.props.onChange(event, lang, lang === getDefaultLanguage());
    }

    handleAddTag(tag, lang) {
        let tags = this.getValue(lang);
        if(!tags) {
            tags = [];
        }
        tags.push(tag.label);
        this.handleChange({
            target: {
                name: this.props.name,
                value: tags,
            },
        }, lang);
    }

    handleRemoveTag(indexToRemove, lang) {
        let tags = this.getValue(lang);
        if(tags) {
            tags = tags.filter((tag, index) => index !== indexToRemove);
            this.handleChange({
                target: {
                    name: this.props.name,
                    value: tags,
                },
            }, lang);
        }
    }

    renderTabContent(lang) {
        // {lang === 'fr' ? this.props.children : this.renderFormElement(lang)}
        return (
            <TabPane key={lang} tabId={lang}>
                <div className="p-1">
                    {this.renderFormElement(lang)}
                </div>
            </TabPane>
        );
    }

    renderFormElement(lang) {
        switch(this.props.type) {
            case 'text':
            case 'textarea':
                return <Input type={this.props.type} name={this.props.name} value={this.getValue(lang)} onChange={event => this.handleChange(event, lang)} />;

            case 'html':
                return <HtmlEditor
                        name={this.props.name}
                        content={this.getValue(lang)}
                        onChange={content => this.handleChange({ target: { name: this.props.name, value: content } }, lang)}
                        autofocus={false}
                />;

            case 'tags':
                return <ReactTags
                    selected={this.getTags(lang)}
                    suggestions={this.getTagsSuggestions(lang)}
                    onDelete={tag => this.handleRemoveTag(tag, lang)}
                    onAdd={key => this.handleAddTag(key, lang)}
                    renderSuggestion={({ text }, query) => <div className="list-group-item">{text}</div>}
                    autocomplete
                    inputFieldPosition="inline"
                    allowDragDrop={false}
                    readOnly={false}
                    autofocus={false}
                    classNames={this.props.classNames}
                    placeholder={this.props.placeholder}
                />;

            default:
                return null;
        }
    }

    render() {
        if(getEnabledLanguages().length) {
            return (
                <div>
                    <Nav tabs className="nav-secondary">
                        {getEnabledLanguages().map((lang, index) => <NavItem key={lang}><NavLink className={classnames({ active: this.state.activeTab === lang })} onClick={() => { this.toggle(lang); }}>{lang}</NavLink></NavItem>)}
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        {getEnabledLanguages().map(lang => this.renderTabContent(lang))}
                    </TabContent>
                </div>
            );
        }
        return this.props.children;
    }
}

IntlTabLangForm.defaultProps = {
    classNames: {},
    suggestions: [],
    children: [],
    placeholder: '',
};

IntlTabLangForm.propTypes = {
    translations: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    classNames: PropTypes.object,
    suggestions: PropTypes.array,
    placeholder: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.node,
    ]),
};

export default IntlTabLangForm;
