import { SWITCH_LANGUAGE, getEnabledLanguages } from './IntlActions';

const initLocale = 'en';
// if(global && global.navigator && global.navigator.language) {
//     const navigatorLanguage = global.navigator.language.split('-')[0];
//     if(LANGUAGES_ENABLED.indexOf(navigatorLanguage) !== -1) {
//         initLocale = navigatorLanguage;
//     }
// }

export const initialState = {
    isInit: false,
    locale: initLocale,
    enabledLanguages: getEnabledLanguages(),
    messages: []
};

const IntlReducer = (state = initialState, action) => {
    switch(action.type) {
        case SWITCH_LANGUAGE: {
            const { type, ...actionWithoutType } = action; // eslint-disable-line
                return {
                    ...state,
                    ...actionWithoutType,
                };
        }

        default:
            return state;
    }
};

export default IntlReducer;
